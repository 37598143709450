@import "homev2/amodule_vars";
@import "homev2/module_mixin.scss";
@import "homev2/slick.scss";
@import "homev2/module_header";
@import "homev2/transition.scss";
@import "homev2/module_slider_slick.scss";
@import "homev2/module_extend.scss";
.tt-carousel-products.slick-slider{
  .slick-slide[class^="col-"]{
    max-width: inherit;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-animated-show-js{
  opacity: 0;
  @include animation-value(opacity $value_speed);
}
.slick-animated-show-js.slick-initialized{
  opacity: 1;
}
.slick-track {
  //margin: auto;
}

.tt-slick-row{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.arrow-location-tab{
  .slick-arrow{
    position: absolute;
    z-index: 1;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    width: 47px;
    height: 47px;
    color: $slick_locationTab_arrow_text;
    background: $slick_locationTab_arrow_bg;
    font-weight: 500;
    @include border-radius(50%);
    @include animation();
  }
  .slick-arrow:hover{
    background: $slick_locationTab_arrow_bg_hover;
    color: $slick_locationTab_arrow_text_hover;
  }
  .slick-arrow:before{
    font-family: $default_font_icon;
    font-size: 20px;
    line-height: 1;
    position:initial;
  }
  .slick-prev:before{
    content: "\e963";
    margin-right: 2px;

  }
  .slick-next:before{
    content: "\e960";
    margin-left: 2px;
  }

  //@media (min-width: 1025px){
  //  &.arrow-location-tab01{
  //    .slick-arrow{
  //      top: -43px;
  //    }
  //  }
  //  .slick-arrow{
  //    top: -33px;
  //  }
  //  .slick-prev{
  //    right:57px;
  //  }
  //  .slick-next{
  //    right:10px;
  //  }
  //}
  //@media (max-width: 1024px){
    .slick-arrow{
      top: 30%;
    }
    .slick-prev{
      left:-8px;
      @include box-shadow(#ddd -1px 1px 2px 1px);
    }
    .slick-next{
      right:-10px;
      @include box-shadow(#ddd 1px 1px 2px 1px);
    }
  @media(max-width: 792px) {
    .slick-prev {
      left: 28px;
    }
    .slick-next {
      right: 28px;
    }
  }
  }
//}
.icon-03:before {
  content: "\ea81";
}
.icon-profile {
  font-size:18px!important;
}
.tt-col-list {
  margin-top:-23px;
  &.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
}
.dropdown-menu.hover{

}
.tt-obj-logo {
  flex: 2 1 auto;
  .logo {
    text-align:left;
    padding:8px 0;
  }
}
.tt-header-row {
  .tt-mobile-parent-account {
    margin-left:auto;
    .tt-account {
      nav {
        ul {
          @extend ._reset-ul-style;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          .dropdown {
            a {
              font-size: 14px;
              line-height: 1.1;
              letter-spacing: 0.04em;
              padding: 14px 12px;
              display: -ms-inline-flexbox;
              display: -webkit-inline-flex;
              display: inline-flex;
              font-family: "Poppins", sans-serif!important;
              font-weight: 400;
              color: #606060;
              cursor: pointer;
              &.btn-sola-blue {
                background-color: #73d6c8;
                color: white;
                border-radius: 50px;
                font-size: 15px;
                padding: 10px 20px;
              }
            }

          }
        }
      }

    }
  }
}
.tt-obj-menu {
  text-align:right;
  .tt-desctop-menu {
    .dropdown.logo {
      display:none;
    }
  }
  &.obj-move-right {
    //text-align:left;
    .tt-desctop-parent-account {
      .tt-account {
        nav {
          ul {
            @extend ._reset-ul-style;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: center;
            -ms-flex-line-pack: center;
            align-content: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            .dropdown {
              a {
                font-size: 14px;
                line-height: 1.1;
                letter-spacing: 0.04em;
                padding: 17px 12px;
                display: -ms-inline-flexbox;
                display: -webkit-inline-flex;
                display: inline-flex;
                font-family: "Poppins", sans-serif!important;
                font-weight: 500;
                color: #282928;
                cursor: pointer;
                &.btn-sola-blue {
                  background-color: #73d6c8;
                  color: white;
                  border-radius: 50px;
                  font-size: 15px;
                  padding: 10px 20px;
                }
              }
              .tt-dropdown-toggle {
                .user-icon {
                  display:none;
                }
                .icon-arrow-down {
                  font-size: 12px;
                  margin: 2px;
                }
              }
              .tt-dropdown-menu {
                .tt-dropdown-inner {
                  padding:20px;
                  ul {
                    align-items:baseline;
                    -webkit-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    li {
                      a {
                        padding:8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }
    }
  }
}
.tt-desctop-menu {
  nav {
    ul {
      li.dropdown {
        a {
          font-family: "Poppins", sans-serif;
          font-weight:500;
          color: #606060;
          text-decoration:none;
          &.btn-sola-blue {
            background-color: #73d6c8;
            color: white;
            border-radius: 50px;
            font-size: 15px;
            padding: 10px 20px;
          }
          &.dropdown-toggle {
            .user-icon {
              display:none;
            }
            .icon-arrow-down {
              font-size: 12px;
              margin: 2px;
            }
          }
        }
        .dropdown-menu.hover {
          left:inherit!important;
          background-color:#444;
          border-radius:10px;
          .col {
            width:100%;
            .tt-megamenu-submenu {
              width:100%;
              div[type="pills"] {
                display:flex;
                justify-content:space-between;

                ul.nav {
                  flex: 0 0 20%;
                  li.active, li {
                    a, a:hover, a:focus {
                      background-color:transparent;
                      font-weight:200;
                      &:after {
                        content: "\e960";
                        font-family: 'sola';
                        padding-left: 10px;
                        top: 0px;
                        right: -10px;
                        position: absolute;
                        color: #777777;
                      }
                    }
                  }
                  li.active, li.c-active {
                    a, a:after {
                        color: #ffbc19!important;
                      }
                    }

                }
                .tab-content {
                  flex:1 1 auto;
                  margin-left:20px;
                  .tab-pane {
                    ul {
                      display: flex;
                      flex-flow: column wrap;
                      max-height: 120px;
                      overflow: auto;
                      //height:250px;
                      li {
                        flex: 0 0 40%;
                        a {
                          color: #aaa;
                          line-height:1.8;
                          font-size:14px;
                        }
                       //flex-basis: 50%;
                        &.active {
                          a {
                            color:#ffbc19;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          a{
            font-size:16px;
            color: #fff;
            font-weight:400;
          }
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-width: 8px;
        border-style: solid;
        bottom: 100%;
        left: 37%;
        margin-left: -8px;
        border-bottom-color: #464646;
      }
          &.centres-dropdown {
            max-height: 540px;
            overflow: unset!important;
            min-width: 321px;
            padding:25px;
            margin-left: -70px;
            .all-centres {
              padding:10px 0;
              a {
                color: #ffbc19;
                font-size: 16px;
                font-family: "Open Sans",sans-serif;
                font-weight: 600;
                letter-spacing: 0;
              }
            }
            ul {
              max-height:335px;
              overflow-y:scroll;
              margin-bottom:0;
              li {
                display:block;
                float:inherit;
                a {
                  color:#fff;
                  padding:3px 0px;
                  font-weight:600;
                  display: block;
                  clear: both;
                  line-height: 1.42857143;
                  white-space: nowrap;
                  span {
                    font-weight:300;
                  }
                  &:hover,
                  &:focus {
                    text-decoration: none;
                    color: #606060;
                    background-color: #fff;
                  }
                }
              }
              &:after {
                ul:after {
                  position: absolute;
                  left: 50%;
                  margin-left: -10px;
                  top: -8px;
                  width: 0;
                  height: 0;
                  content:'';
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-bottom: 10px solid #BB0927;
                }
              }
            }
            /* scrollbar width */
            ul::-webkit-scrollbar {
              width: 7px;
            }

            /* scrollbar Track */
            ul::-webkit-scrollbar-track {
              background: transparent;
              border-radius: 50px;
            }

            /* scrollbar Handle */
            ul::-webkit-scrollbar-thumb {
              background: rgba(96, 96, 96, .76);
              border-radius: 50px;
            }

            /* scrollbar Handle on hover */
            ul::-webkit-scrollbar-thumb:hover {
              background: rgba(96, 96, 96, .9);
            }
            .option-filter {
              input {
                border:none;
                outline:none;
                color: #fff;
                border-radius:20px;
                background-color: #606060;
                padding:3px 20px;
                font-family: "Poppins",sans-serif;
                font-weight: 500;
                width: 100%;
                margin-bottom: 15px;
                letter-spacing: 1px;
                font-size: 16px;
                height:40px;
                &::placeholder {
                  color: #fff;
                  font-family: "Poppins", sans-serif;
                  text-transform: uppercase;
                  font-size:13px;
                  font-weight:500;
                }
              }
            }
            padding: 25px;
            border-radius: 10px;
            background-color: #444;
          }
        }
        &.tt-megamenu-col-account {
          .dropdown-menu.hover {
            &:before {
              content: "";
              display: block;
              position: absolute;
              width: 0;
              height: 0;
              border-color: transparent;
              border-width: 10px;
              border-style: solid;
              bottom: 100%;
              right: 18%;
              left: auto;
              margin-left: -8px;
              border-bottom-color: #fff;
            }
          }
        }
      }

    }
  }
}
.tt-stuck-nav.stuck {
  .tt-stuck-parent-logo {
      .dropdown.logo {
        a {
          padding:0;
          img {
            height:30px;
            width:30px;

          }
        }
    }
  }
  .tt-stuck-parent-account {
    .tt-account {
      nav {
        ul {
          @extend ._reset-ul-style;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          .dropdown {
            a {
              font-size: 15px;
              line-height: 1.1;
              letter-spacing: 0.04em;
              padding: 13px 12px;
              display: -ms-inline-flexbox;
              display: -webkit-inline-flex;
              display: inline-flex;
              font-family: "Poppins", sans-serif!important;
              font-weight: 500;
              color: #606060;
              cursor: pointer;
              &.btn-sola-blue {
                background-color: #73d6c8;
                color: white;
                border-radius: 50px;
                font-size: 15px;
                padding: 10px 20px;
              }
              &.dropdown-toggle {
                .user-icon {
                  display:none;
                }
                .icon-arrow-down {
                  font-size: 12px;
                  margin: 2px;
                }
              }
            }
            .tt-dropdown-menu {
              .tt-dropdown-inner {
                padding:20px;
                ul {
                  align-items:baseline;
                  -webkit-flex-direction: column;
                  -ms-flex-direction: column;
                  flex-direction: column;
                  li {
                    a {
                      padding:8px;
                    }
                  }
                }
              }
            }
            .tt-dropdown-toggle {
              .user-icon {
                display:none;
              }
              .icon-arrow-down {
                font-size: 12px;
                margin: 2px;
              }
            }
          }
        }
      }

    }
  }
}

@media(max-width: 1024px) {
  .tabsWrapper {
    margin-top:20px!important;
    .home-carousel {
      .title {
        text-align:center;
      }
    }
  }
  .tt-mobile-parent-account, .tt-stuck-parent-account {
    .tt-account {
      .dropdown {
        .tt-dropdown-toggle {
          span.user-name {
            display:none;
          }
          span.user-icon {
            display:block!important;
          }
        }
        .tt-dropdown-menu {
          .tt-dropdown-inner {
            padding:20px;
            ul {
              align-items:baseline;
               -webkit-flex-direction: column;
              -ms-flex-direction: column;
              flex-direction: column;
              li {
                a {
                  padding:8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: 991px) {
  .centres-wrapper {
    .title-row {
      .title {
        text-align:center;
      }
    }
    .centre-details {
      .col-md-4 {
        .col-sm-6 {
          .info {
            &.address {
              line-height:22px;
            }
            &.social {
              margin-top:7px;
            }
          }


        }
        .col-sm-5 {
          hr:first-child {
            display:none;
          }
        }
      }
    }
  }

}
@media(max-width: 767px) {
  .tt-header-row {
    .tt-mobile-parent-account {
      .tt-search {
        padding-bottom:0px;
      }
      .tt-account {
        nav {
          ul {
            .dropdown {
              a {
                padding: 14px 12px;
                &.btn-sola-blue {
                  padding:11px 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  .tt-stuck-nav.stuck {
    .tt-stuck-parent-account {
      .tt-account {
        nav {
          ul {
            .dropdown {
              a.btn-sola-blue {
                padding:9px 10px;
              }
            }
          }
        }
      }
    }
  }
  .centres-wrapper {
    .container {
      padding-top:15px!important;
      padding:0 10px;
    }
    .dropdown {
      margin-bottom:20px;
    }
    .centre-details {
      .centre-title {
        text-align:center;
        font-size:20px;
        font-weight:600;
      }
      .col-md-4 {
        .col-sm-6 {
          .info.social {
            margin-top:15px;
            margin-bottom:15px;
          }
        }
        .col-sm-5 {
          hr:first-child {
            display:block;
          }
        }
      }
    }
    .photos {
      display:none;
    }
  }
  //.cta {
  //  height:466px;
  //  .cta-text {
  //    .subtitle {
  //      margin-right:20%;
  //      margin-left:20%;
  //      margin-top:20px;
  //    }
  //    .big-title {
  //      margin-right:15%;
  //      margin-left:15%;
  //    }
  //    .cta-btn {
  //      span {
  //        padding:18px 55px;
  //      }
  //    }
  //  }
  //}
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.tt-megamenu-col-account {
  .tt-dropdown-toggle {
    .dropdown-menu.hover {
      &:before {
        left:inherit;
        right:20%;
      }
    }
  }
}
.content-placeholder {
  display: inline-block;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  height: inherit;
  position: relative;
}


/* leftColumn mobile toggle*/
body.no-scroll:not(.touch-device),
html.win.ie body.no-scroll{
  padding-right: 17px;
}
body.no-scroll{
  overflow: hidden;
  width: 100%;
  position: fixed;
  z-index: 0;
}
.modal-filter{
  background: $listing_filter_modal_bg;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 11;
  display: block;
  @include opacity(0);
  @include animation-value(background $value_speed);
}
.tt-btn-col-close{
  display: none;
  text-decoration: none;
  position: relative;
  width: 286px;
  left: -150%;
  top: 0;
  z-index: 13;
  @include animation-value(left 0.3s);
  a{
    display: block;
    position: relative;
    padding: 15px 14px 13px 28px;
    font-size: 14px;
    text-align: left;
    background: transparent;
    @include animation-value(color $value_speed);
  }
  a:before{
    content: "\e962";
    position: relative;
    font-size: 14px;
    line-height: 1;
    font-family: $default_font_icon;
    margin: 0 5px 0 0;
    @include animation-value(color $value_speed);
  }
  @media (min-width: 1025px){
    background:$listing_filter_btn_close_desctope_bg;
    a{
      color: $listing_filter_btn_close_desctope_text;
      font-family: $default_font2;
      font-weight: 500;
      text-transform: uppercase;
      padding: 22px 14px 20px 28px;
    }
    a:before{
      font-size: 20px;
      top:4px;
      font-weight: normal;
      letter-spacing: 0.04em;
      color: $listing_filter_btn_close_desctope_icon;
    }
    a:hover{
      color: $listing_filter_btn_close_desctope_text_hover;
      &:before{
        color: $listing_filter_btn_close_desctope_icon_hover;
      }
    }
    background: -webkit-linear-gradient(top, $listing_filter_btn_close_gradient_bg01, $listing_filter_btn_close_gradient_bg02 20%);
    background: -o-linear-gradient(top, $listing_filter_btn_close_gradient_bg01, $listing_filter_btn_close_gradient_bg02 20%);
    background: -moz-linear-gradient(top, $listing_filter_btn_close_gradient_bg01, $listing_filter_btn_close_gradient_bg02 20%);
    background: linear-gradient(to top, $listing_filter_btn_close_gradient_bg01, $listing_filter_btn_close_gradient_bg02 20%);
  }
  @media (max-width: 1024px){
    background:$listing_filter_btn_close_mobile_bg;
    border-bottom: 1px solid $listing_filter_btn_close_mobile_border;
    a{
      color: $listing_filter_btn_close_mobile_text;
    }
    a:before{
      top: 1px;
      color: $listing_filter_btn_close_mobile_icon;
    }
    a:hover{
      color: $listing_filter_btn_close_mobile_text_hover;
      &:before{
        color: $listing_filter_btn_close_mobile_icon_hover;
      }
    }
    a:after{
      content: '';
      position: absolute;
      bottom: -11px;
      width: 100%;
      height: 10px;
      left: 0;
      background: -webkit-linear-gradient(top, $listing_filter_btn_close_gradient_bg01, $listing_filter_btn_close_gradient_bg02 60%);
      background: -o-linear-gradient(top, $listing_filter_btn_close_gradient_bg01, $listing_filter_btn_close_gradient_bg02 60%);
      background: -moz-linear-gradient(top, $listing_filter_btn_close_gradient_bg01, $listing_filter_btn_close_gradient_bg02 60%);
      background: linear-gradient(to top, $listing_filter_btn_close_gradient_bg01, $listing_filter_btn_close_gradient_bg02 60%);
    }
  }
}
.aside.leftColumn{
  @media (max-width:1023px){
    position: fixed;
    display: block;
    margin: 0;
    background: $listing_filter_col_bg_mobile;
    z-index: 14;
    top: 0;
    left: -150%;
    height: 100%;
    width: 287px;
    max-width: inherit;
    text-align: left;
    @include animation-value(left 0.3s);
    .tt-btn-col-close{
      display: block;
    }
  }
}
.aside.leftColumn:not(.desctop-no-sidebar){
  @media (min-width:1025px){
    .tt-filter-detach-option{
      display: none;
    }
  }
}

/*
	leftColumn desctop toggle (listing-metro.html, listing-not-sidebar-full-width.html)
	*add class in html desctop-no-sidebar
*/
.aside.leftColumn.desctop-no-sidebar{
  @media (min-width:1025px){
    position: fixed;
    display: block;
    margin: 0;
    background: $listing_filter_col_bg_desctope;
    z-index: 14;
    top: 0;
    left: -150%;
    height: 100%;
    width: 287px;
    max-width: inherit;
    text-align: left;
    overflow-x: hidden;
    @include animation-value(left 0.3s);
    .tt-btn-col-close{
      display: block;
    }
    .tt-btn-col-close + .tt-collapse{
      margin-top: 46px;
    }
  }
}

/* leftColumn column open*/
.aside.leftColumn.column-open{
  left: 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 50px;
  padding-bottom: 20px;
  .tt-btn-col-close{
    left: 0;
    position: fixed;
  }
}
html.ie{
  .aside.leftColumn.desctop-no-sidebar,
  .aside.leftColumn,
  .tt-btn-col-close{
    @include animation-value(left 0s);
  }
}

/*
	aside listing block
	*leftColumn
	*rightColumn
*/
.tt-collapse{
  position: relative;
  background-color:#fff;
  padding:10px;
  .tt-collapse-title{
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.03em;
    padding: 0 20px 13px 0;
    cursor: pointer;
    text-transform:uppercase;
    color: $listing_aside_collapse_title_text;
    border-bottom: 1px solid $listing_aside_collapse_title_border;
    @include animation-value(color $value_speed);
  }
  .tt-collapse-title:after{
    position: absolute;
    display: block;
    top: 7px;
    right: -2px;
    font-family: $default_font_icon;
    font-size: 10px;
    content: "\e975";
    line-height: 1;
    font-weight: normal;
    color: $listing_aside_collapse_title_icon;
  }
  .tt-collapse-title:hover{
    color: $listing_aside_collapse_title_text_hover;
    &:after{
      color: $listing_aside_collapse_title_icon_hover;
    }
  }
  .tt-collapse-title + .tt-collapse-content{
    margin-top: 20px;

    .tt-list-row {
      margin-top: -8px;
      padding-right:10px;
    }
    .tt-scroll-box {
      max-height: 300px;
      position:relative;
      //overflow: auto!important;
    }
    ///* scrollbar Track */
    //.tt-scroll-box::-webkit-scrollbar-track {
    //	background: transparent;
    //	border-radius: 50px;
    //}
    //
    ///* scrollbar Handle */
    //.tt-scroll-box::-webkit-scrollbar-thumb {
    //	background: transparent;//#8bc53f6b;
    //	border-radius: 50px;
    //}
    //
    ///* scrollbar Handle on hover */
    //.tt-scroll-box::-webkit-scrollbar-thumb:hover {
    //	background: rgba(96, 96, 96, .9);
    //}
    //.tt-scroll-box::-webkit-scrollbar {
    //	width: 7px;
    //}
    .tt-filter-list{
      margin-top: -7px;
    }
    .tt-list-inline,
    .tt-aside{
      margin-top: -4px;
    }
  }



}
.column-open .tt-collapse.hide ~ div{
  @media (min-width: 1025px){
    margin-top: 46px;
  }
  @media (max-width: 1024px){
    margin-top: 32px;
  }
}
.open.tt-collapse > .tt-collapse-title:after{
  content: "\e968";
}

.tt-collapse:not(:last-child){
  margin-bottom: 33px;
}
.tt-btn-col-close + .tt-collapse{
  margin-top: 32px;
}
/* desctope (listing-not-sidebar-full-width.html)*/
.column-open .tt-btn-col-close + .tt-collapse{
  @media (min-width:1025px){
    margin-top: 20px;
  }
}

/* tt-filter-list (aside listing block)*/
.tt-filter-list{
  @extend ._reset-ul-style;
  margin-top: -3px;
  li{
    position: relative;
    a{
      display: block;
      padding: 3px 0 3px 20px;
      letter-spacing: 0.01em;
      color: $listing_aside_list_text;
      @include animation-value(color $value_speed);
    }
    a:before{
      content: "\e962";
      position: absolute;
      left: -1px;
      top: 7px;
      font-size: 12px;
      line-height: 1;
      font-family: $default_font_icon;
      color: $listing_aside_list_icon;
    }
    a:hover{
      color:$listing_aside_list_text_hover;
      &:before{
        color: $listing_aside_list_icon_hover;
      }
    }
  }
  li.active{
    color: $listing_aside_list_text_active;
    a:before{
      color:$listing_aside_list_icon_active;
    }
  }
}
.tt-filter-list + .btn-link-02{
  margin-top: 6px;
}

/* tt-list-row (aside simple list)*/
.leftColumn,
.rightColumn{
  .tt-list-row{
    li{
      a{
        //display:flex;
        //justify-content:space-between;
      }
    }
  }
}
.tt-search-type2 {
  position: relative;
  margin-bottom:20px;
  display:flex;
  justify-content: center;
  .input-search {
    width:50em;
    max-width:100%;
    .tt-search-input {
      border: 1px solid #e9e7e7;
      outline: 0;
      padding: 0px 30px;
      -moz-appearance: none;
      -webkit-appearance: none;
      color: #777777;
      background: #fff;
      outline: none;
      line-height:30px;
      width:100%;
      max-width:100%;
    }
  }

  .tt-btn-search {
    position: relative;
    padding: 0px 6px;
    border: none;
    outline: none;
    color: #ffffff;
    background: $default_color;
    font-size: 14px;
    line-height:30px;
    font-weight: 500;
    letter-spacing: 0.05em;
    cursor: pointer;
    &:hover {
      color: #ffffff;
      background: $default_color;
    }
  }
}
.tt-search-type2 [class^="icon"] {
  position: relative;
  font-size: 16px;
  top: 2px;
  left: 27px;
  color: #191919; }

.tt-search-type2 .tt-btn-search:hover {
}
.tt-search-type2 .tt-search-input:focus::-webkit-input-placeholder {
  color: transparent; }
.tt-search-type2 .tt-search-input:focus:-moz-placeholder {
  color: transparent; }
.tt-search-type2 .tt-search-input:focus::-moz-placeholder {
  color: transparent; }
.tt-search-type2 .tt-search-input:focus:-ms-input-placeholder {
  color: transparent; }

.tt-list-row{
  @extend ._reset-ul-style;
  margin-top: -2px;
  li{
    a{
      color: $list_row_text;
      padding:5px 0;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      @include animation-value(color $value_speed);
      .checkbox-group {
        margin-bottom:0px;
        label {
          .box {
            margin-top:0px;
          }
          .check {
            top:0px;
          }
        }
      }
    }
    a:hover{
      color: $list_row_text_hover;
    }
  }
  li.active{
    a{
      color: $list_row_text_active;
    }
  }
  li:last-child{
    a{
      padding-bottom: 0;
    }
  }
}
.tt-list-row + [class^="btn"]{
  margin-top: 5px;
}

/* tt-aside (aside img block)*/
.tt-aside{
  .tt-item:not(:first-child){
    margin-top: 16px;
  }
  .tt-item{
    display: flex;
    flex-wrap: wrap;
    .tt-img{
      flex: 0 0 calc(50% - 20px);
      max-width: calc(50% - 20px);
      width: calc(50% - 20px);
      margin-right: 20px;
      margin-top: 4px;
      position: relative;
      img{
        max-width: 100%;
        height: auto;
      }
      .tt-img-default{
        -webkit-transition: opacity 0.3s ease-out;
        transition: opacity 0.3s ease-out;
        overflow: hidden;
        top: 0;
        left: 0;
      }
      .tt-img-roll-over{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
        width: 100%;
        -webkit-transition: opacity 0.3s ease-out;
        transition: opacity 0.3s ease-out;
      }
    }
    .tt-img + .tt-content{
      flex: 0 0 50%;
      max-width: 50%;
    }
    .tt-content{
      .tt-title{
        font-size: 14px;
        font-weight: normal;
        font-family:$default_font;
        color: $listing_aside_img_block_title;
        @include animation-value(color $value_speed);
      }
      .tt-price{
        color: $listing_aside_img_block_price;
        font-size: 16px;
        font-weight: 500;
        .sale-price{
          color: $listing_aside_img_block_price_new;
          margin-right: 4px;
        }
        .old-price{
          color: $listing_aside_img_block_price_old;
          text-decoration: line-through;
        }
      }
    }
  }
  .tt-item:hover{
    .tt-img-default +.tt-roll-over{
      @include opacity(0);
    }
    .tt-img-roll-over,
    [class^="tt-img"]:last-child{
      @include opacity(1);
    }
    .tt-title{
      color: $listing_aside_img_block_title_hover;
    }
  }
}
/* tt-filter-list (aside filter block)*/
.tt-filter-detach-option{
  .filters-mobile{
    .filters-row-select:not(:first-child){
      margin-top: 20px;
    }
    .filters-row-select{
      select{
        width: 100%;
        font-size: 14px;
        line-height: 1;
        border-color:transparent;
        outline: none;
        border: none;
        background:$listing_aside_mobile_filter_select_bg;
        color: $listing_aside_mobile_filter_select_text;
        cursor: pointer;
        padding: 9px 5px 12px 9px;
        letter-spacing: 0.01em;
        @include animation-value(color $value_speed);
      }
      select:hover{
        color: $listing_aside_mobile_filter_select_text_hover;
      }
      select:not(:first-child){
        margin-top: 20px;
      }
    }
  }
}
/* tt-product-listing-masonry (listing-metro.html)*/
.tt-filters-options + .tt-product-listing-masonry{
  @media (min-width: 1025px){
    margin-top: 28px;
  }
  @media (max-width: 1024px){
    margin-top: -4px;
  }
  @media (max-width: 575px){
    margin-top: 8px;
  }
}
.tt-product-listing-masonry{
  .tt-product-init{
    margin: -10px -10px;
  }
  .grid:after{
    content: '';
    display: block;
    clear: both;
  }
  .element-item{
    position: relative;
    float: left;
  }
  @media (min-width:1024px){
    .element-item{
      width: 25%;
      padding: 10px;
    }
    .element-item.double-size{
      width: 50%;
    }
  }
  @media (max-width:1199px){
    margin-top: -40px;
    .element-item{
      width: 33.333%;
      margin-top: 40px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (max-width:789px){
    .element-item{
      width: 50%;
    }
  }
  @media (max-width:575px){
    margin-top: -30px;
    .element-item{
      margin-top: 30px;
    }
  }
}
// add new view
.tt-product-listing.tt-row-view{
  > .tt-col-item{
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.tt-grid-switch{
  color: #191919;
  font-size: 20px;
  margin-left: 10px;
  margin-top: -1px;
  text-decoration: none;
  @include opacity(0.15);
  @include animation-value(color $value_speed);
  &:hover,
  &.active{
    color: #191919;
    @include opacity(1);
  }
}
.tt-quantity.tt-disabled{
  a.active{
    @include opacity(0.3);
  }
}

$loaderHeightWidth:120px;
$loaderPrimaryColor:#4abdac;
$loaderBorderSize:3px;
$loaderMinHeightWidth:10px;
$loaderMaxHeightWidth:400px;
$animationTime: 0.3s;
$animationStyle:linear;
$animationStyle:cubic-bezier(.23,.78,.69,.84);

.spin-loader {
  position:fixed;
  top:35%;
  left:50%;
  margin-left:-($loaderHeightWidth/2);
  height:$loaderHeightWidth;
  width:$loaderHeightWidth;
  background:transparent;
  border:$loaderBorderSize solid;
  border-color:
          $loaderPrimaryColor
          $loaderPrimaryColor
          $loaderPrimaryColor
          darken($loaderPrimaryColor, 30);
  border-radius:50%;
  transition:all $animationTime $animationStyle;
  animation: spin 0.75s infinite linear;
}

.loader-overlay {
  position:fixed;
  top:0;
  bottom:0;
  right:0;
  left:0;
  z-index:10000000;
  background:#f5f5f5;
  -moz-transition: opacity $animationTime $animationStyle;
  -o-transition: opacity $animationTime $animationStyle;
  -webkit-transition: opacity $animationTime $animationStyle;
  transition: opacity $animationTime $animationStyle;

  &.ng-hide {
    opacity: 0;

    .spin-loader.shrink {
      top: calc(25% + #{($loaderHeightWidth/2)});
      margin-left:0;
      width:$loaderMinHeightWidth;
      height:$loaderMinHeightWidth;
    }
    .spin-loader.expand {
      top: calc(25% - #{($loaderMaxHeightWidth/4)});
      margin-left:-($loaderMaxHeightWidth/2);
      width:$loaderMaxHeightWidth;
      height:$loaderMaxHeightWidth;
    }
  }
}


@keyframes spin {
  0% {
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
}